import React, {useEffect, useRef} from "react";
import me from "../img/me.jpg";
import {useRouteStore} from "../stores/RouteStore";
import {observer} from "mobx-react-lite";

const About = observer(({ id }) => {
    const routeStore = useRouteStore();
    const ref = useRef(null);

    useEffect(() => {
        routeStore[id] = ref;
    }, []);

    return (
        <div className="fixed page" ref={ref}>
            <div className="page-inner">
                <h1>about me</h1>
                <p>
                I’m Loïs. I'm an interaction designer living in Arnhem & Amsterdam. In my spare time I have a couple of hobbies which include crocheting, going on walks, trying new foods and being with friends. I enjoy nature, being outside and learning new skills, whatever it may be. 
                <br/>
                <br/>
                What I enjoy most about UX design is the process of transforming an idea into a fully-fledged and user-friendly concept. I have learned that collaborating with different disciplines can be immensely helpful in developing a concept that works for everyone within the target group. Feedback and working together is perhaps the most crucial aspect of this field. 
                </p>
                <img src={me} alt="Picture of me" className="portrait-picture"/>
            </div>
        </div>
    )
});

export default About;
